import { namedStore } from "../store";

interface NewType {
  name: string;
  type: { [key: string]: string };
}

const capName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const getTypes = (
  jsonObj: { [key: string]: any },
  splitType: boolean = false,
  typeName?: string,
  depth = 0
): NewType[] => {
  const listOfTypes: NewType[] = [];

  const rootType = Object.entries(jsonObj).reduce((acc: any, [key, val]) => {
    if (Array.isArray(val)) {
      if (typeof val[0] === "object") {
        if (splitType) {
          const typeName = capName(key);
          listOfTypes.push(...getTypes(val[0], splitType, typeName, depth + 1));
          acc[key] = `Array<${typeName}>`;
        } else {
          let spaces = "  ";
          for (let i = 0; i < depth; i++) {
            spaces += "  ";
          }

          const arrayTypes = getTypes(val[0], splitType, typeName, depth + 1);
          acc[key] = `Array<${typeToString(
            arrayTypes[0],
            splitType,
            spaces,
            true
          )}>`;
        }
      } else {
        acc[key] = `Array<${typeof val[0]}>`;
      }
    } else if (typeof val === "object") {
      const typeName = capName(key);
      const typeVal = getTypes(val, splitType, typeName, depth + 1);
      if (splitType) {
        if (typeVal.length > 1) {
          listOfTypes.push(...typeVal);
        } else {
          listOfTypes.push({
            name: typeName,
            type: typeVal[0].type,
          });
        }
        acc[key] = typeName;
      } else {
        acc[key] = typeVal[0];
      }
    } else {
      acc[key] = typeof val;
    }
    return acc;
  }, {});

  listOfTypes.push({
    name: typeName ?? "Root",
    type: rootType,
  });

  return listOfTypes;
};

const typeToString = (
  newType: NewType,
  splitType: boolean = false,
  spaces = "",
  isArray = false
) => {
  let newTypeString = !spaces ? `export interface ${newType.name} {\n` : `{\n`;
  Object.entries(newType.type).forEach(([key, val]) => {
    if (typeof val === "object") {
      newTypeString += `${spaces}  ${key}: ${typeToString(
        val,
        splitType,
        spaces + "  "
      )}\n`;
    } else {
      newTypeString += `${spaces}  ${key}: ${val};\n`;
    }
  });
  return newTypeString + `${spaces}}${!spaces ? "\n\n" : !isArray ? ";" : ""}`;
};

export const jsonToType = (input: string, splitType: boolean = false) => {
  let jsonObj: any;
  try {
    jsonObj = JSON.parse(input);
  } catch (ex) {
    namedStore.modal.updateState({
      title: "Invalid JSON",
      content: "Please fix the input and try again.",
    });
    return "";
  }

  const newTypes = getTypes(jsonObj, splitType);

  return newTypes.reduce((acc: string, entry: NewType) => {
    return (acc += typeToString(entry, splitType));
  }, "");
};
