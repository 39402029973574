import styled from "styled-components";

export const ToolsSection = styled.div`
  padding-top: 0.1vh;
  height: 7vh;
  line-height: 7vh;
  background: #dddddd;

  @media (max-width: 768px) {
    line-height: 5vh;
  }
`;

export const SmallToolsSection = styled(ToolsSection)`
  height: auto;
  line-height: 5vh;
  border-bottom: solid 1px #bbbbbb;
`;
