import styled from "styled-components";

export const ViewWrapper = styled.section`
  position: relative;
  margin: auto;
  padding-bottom: 1em;
  width: 100%;
  height: 100%;
  max-height: 932px;
  max-width: 1400px;
  background: #efefef;
  border-radius: 20px;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 5%);
`;
