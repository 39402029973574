import { useModal } from "./modal.hook";
import "./modal.css";

export const ModalView = () => {
  const { modal, setModal } = useModal();

  const acceptPrompt = () => {
    try {
      if (modal?.action) {
        modal?.action();
      }
    } finally {
      setModal(null);
    }
  };

  return (
    <>
      {modal && (
        <section className="popup-window-bg">
          <div className="popup-window">
            <div>
              <h1>{modal.title}</h1>
              <p>{modal.content}</p>
            </div>
            <button onClick={() => setModal(null)} className="popup-btn">
              {modal.action ? "Cancel" : "Close"}
            </button>
            {modal.action && (
              <button onClick={acceptPrompt} className="popup-btn float-right">
                Proceed
              </button>
            )}
          </div>
        </section>
      )}
    </>
  );
};
