import styled from "styled-components";

export const SingleInput = styled.input`
  background: #fff;
  color: #666666;
  font-size: 1em;
  border: solid 2px #cccccc;
  border-radius: 8px;
  outline: 0;
  padding: 0.2em 0.5em 0;

  &:disabled {
    opacity: 0.3;
  }
`;
