import styled from "styled-components";

export const IconButton = styled.i`
  margin: 0.5em;
  padding: 0.5em 0.6em;
  background: #ebebeb;
  border-radius: 10px;
  border: solid 3px #efefef;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
  text-align: center;

  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.big {
    padding: 0.5em 2em;
  }

  &.cta {
    background: #c2eaa8;
  }

  &:active {
    background: #e3e3e3;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 25%);
  }
`;
