import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import JsonToCsvApp from "./apps/json-to-csv/json-to-csv.app";
import { subdomain } from "./global-config";
import "./styles.css";
import JsonToTypescriptApp from "./apps/json-to-typescript/json-to-typescript.app";
import { ModalView } from "./apps/libs/modal";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      {["json-to-csv", "csv-to-json"].includes(subdomain) && <JsonToCsvApp />}
      {["localhost:3000", "json-to-typescript", "json-to-type"].includes(
        subdomain
      ) && <JsonToTypescriptApp />}
      <ModalView />
    </StrictMode>
  );
}
