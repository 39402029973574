import { useRef, useState } from "react";

import { jsonToCsv, saveCsv } from "../libs/converter";
import { IconButton, SingleInput } from "../libs/shared";
import { JsonToCsvPost } from "../libs/posts";
import {
  ViewWrapper,
  ViewTitle,
  SmallToolsSection,
  ToolsSection,
  LeftInput,
  RightInput,
} from "../libs/shared/ui";
import { subdomain } from "../../global-config";
import { csvToJson } from "../libs/converter/csv-to-json";
import { saveJson } from "../libs/converter/save-json";
import { FileInput } from "../libs/shared/ui/file-input";

export default function JsonToCsvApp() {
  const fileRef = useRef<HTMLInputElement>(null);
  const [path, setPath] = useState("");
  const [textInput, setTextInput] = useState<string | any>("");
  const [secondInput, setSecondInput] = useState("");
  const isCsvToJson = subdomain === "csv-to-json";

  const clearFileInput = () => {
    if (fileRef.current) {
      fileRef.current.value = "";
    }
  };

  const onFileChange = (event: any) => {
    const files = event.target.files;
    var reader = new FileReader();
    reader.onload = function () {
      setTextInput(reader.result);
    };
    if (files[0]) reader.readAsText(files[0]);
  };

  const convertJsonToCsv = (download = false) => {
    try {
      const { header, content } = jsonToCsv(textInput, path);
      setSecondInput(`${header}${content}`);
      if (download) saveCsv(header, content);
    } catch (ex) {
      console.error("Failed to convert: ", ex);
    }
  };

  const convertCsvToJson = (download = false) => {
    try {
      const res = csvToJson(textInput);
      setSecondInput(res);
      if (download) saveJson(res);
    } catch (ex) {
      console.error("Failed to convert: ", ex);
    }
  };

  return (
    <>
      <ViewWrapper>
        <ViewTitle>{isCsvToJson ? "CSV to JSON" : "JSON to CSV"}</ViewTitle>
        <SmallToolsSection>
          <FileInput>
            <input ref={fileRef} type="file" onChange={onFileChange} />
          </FileInput>
          {!isCsvToJson && (
            <>
              JSON data path:{" "}
              <SingleInput
                onChange={(event: any) => setPath(event.target.value)}
                placeholder="Ex: parent.child.list"
              />
            </>
          )}
        </SmallToolsSection>
        <ToolsSection>
          <IconButton
            title="Clear"
            onClick={() => {
              setTextInput("");
              setSecondInput("");
              clearFileInput();
            }}
            className="fa-regular fa-trash-can"
          />
          <IconButton
            title="Convert"
            onClick={
              isCsvToJson ? () => convertCsvToJson() : () => convertJsonToCsv()
            }
            className="fa-solid fa-arrow-right-arrow-left big cta"
          />
          <IconButton
            title="Download"
            onClick={
              isCsvToJson
                ? () => convertCsvToJson(true)
                : () => convertJsonToCsv(true)
            }
            className="fa-solid fa-download"
          />
        </ToolsSection>
        <LeftInput
          value={textInput}
          onChange={(event: any) => setTextInput(event.target.value)}
          placeholder={isCsvToJson ? "Add CSV data here" : "Add JSON data here"}
        ></LeftInput>
        <RightInput
          value={secondInput}
          onChange={(event: any) => setSecondInput(event.target.value)}
          placeholder={
            !isCsvToJson
              ? "CSV data will show up here"
              : "JSON data will show up here"
          }
        ></RightInput>
      </ViewWrapper>
      <JsonToCsvPost />
    </>
  );
}
