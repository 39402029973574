const isStringInCsv = (entry: string): boolean => {
  return entry[0] === '"' && entry[entry.length - 1] === '"';
};

export const csvToJson = (input: string, delimiter: string = ","): string => {
  const lines = input.split("\n");
  const [headerLine, ...rows] = lines;
  let keys: Array<string> = headerLine.split('"').join('').split(delimiter);

  const list = rows.map((entry) => {
    return entry
      .split(delimiter)
      .reduce((acc: { [key: string]: any }, item, idx) => {
        if (isStringInCsv(item)) {
          acc[keys[idx]] = item.slice(1, item.length - 1);
        } else if (item === "true") {
          acc[keys[idx]] = true;
        } else if (item === "false") {
          acc[keys[idx]] = false;
        // @ts-ignore:next-line
        } else if (item && !isNaN(item)) {
          acc[keys[idx]] = parseFloat(item);
        } else {
          acc[keys[idx]] = item;
        }
        return acc;
      }, {});
  });

  return JSON.stringify(list);
};
