import { useStore } from "../store/store.hook";

export interface Modal {
  title: string;
  content: string;
  action?: () => void;
}

export const useModal = (): {
  modal?: Modal;
  updateModal: (partialState: any) => void;
  setModal: any;
} => {
  const { state, updateState, setState } = useStore("modal", null);

  return { modal: state, updateModal: updateState, setModal: setState };
};
