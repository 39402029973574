import styled from "styled-components";

export const InputBase = styled.textarea`
  display: inline-block;
  padding: 1em;
  width: 50%;
  height: 50vh;
  border: none;
  outline: none;

  @media (max-width: 768px) {
    width: 100%;
    height: 30vh;
  }
`;

export const LeftInput = styled(InputBase)``;

export const RightInput = styled(InputBase)`
  border-left: solid 3px #dddddd;

  @media (max-width: 700px) {
    border-left: none;
  }
`;
