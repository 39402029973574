import styled from "styled-components";

export const FileInputStyle = styled.button`
  position: relative;
  margin: 0 1em;
  padding: 0.2em 0.8em 0.1em;
  border: none;
  background: none;
  cursor: initial;
  border: dashed 3px rgba(200, 200, 200, 0);
  border-radius: 0.5em;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: dashed 3px rgba(200, 200, 200, 1);
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const FileInput = (props: any) => {
  return (
    <FileInputStyle>
      <i className="fa-solid fa-arrow-up-from-bracket"></i> Load From File
      {props.children}
    </FileInputStyle>
  );
};
