import { namedStore } from "../store";

export const jsonToCsv = (
  input: string,
  path: string,
  delimiter: string = ","
) => {
  let jsonObj: any;
  try {
    jsonObj = JSON.parse(input);
  } catch (ex) {
    namedStore.modal.updateState({
      title: "Invalid JSON",
      content: "Please fix the input and try again.",
    });
  }

  let list = path
    ? path.split(".").reduce((acc: any, key: string) => {
        if (typeof jsonObj[key] === "object") return jsonObj[key];
        return acc;
      }, null)
    : jsonObj;
  list = Object.values(list);

  const header = `${Object.keys(list[0])
    .map((entry) => `"${entry}"`)
    .join(delimiter)}\n`;

  const content = list.reduce((acc: string, item: any) => {
    return (acc += `${Object.values(item)
      .map((entry) => `"${entry}"`)
      .join(delimiter)}\n`);
  }, "");

  return { header, content };
};
