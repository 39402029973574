import { Dispatch, SetStateAction } from "react";

export const globalAppState: any = {};
// @ts-ignore
window.globalAppState = globalAppState;

export class StoreService {
  nameSpace = "";
  subs: Array<Dispatch<SetStateAction<any>>> = [];
  state = {};

  constructor(newNameSpace: string, defaultState: { [key: string]: any } = {}) {
    this.nameSpace = newNameSpace;
    globalAppState[this.nameSpace] = defaultState;
    this.state = globalAppState[this.nameSpace];
  }

  subscribe = (setState: Dispatch<SetStateAction<any>>): number => {
    return this.subs.push(setState) - 1;
  };

  unsubscribe = (index: number) => {
    this.subs.splice(index, 1);
  };

  setState = (newState: any) => {
    globalAppState[this.nameSpace] = newState;
    this.state = globalAppState[this.nameSpace];
    this.subs.forEach((setSubState) => {
      setSubState(this.state);
    });
  };

  updateState = (partialState: any) => {
    this.setState({
      ...globalAppState[this.nameSpace],
      ...partialState,
    });
  };
}
