import { useEffect, useState } from "react";
import { PlainButton } from "./plain-button";

export const RadioButton = (props: {
  onChange: (val: any) => void;
  label?: string;
  selected?: any;
  value?: any;
}) => {
  const { label, selected, value, onChange } = props;
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (value) {
      if (selected === value) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    } else {
      setIsSelected(selected);
    }
  }, [selected, value]);

  const onClick = () => {
    const flag = !isSelected;
    setIsSelected(flag);
    if (value) {
      onChange(value);
    } else {
      onChange(flag);
    }
  };

  return (
    <PlainButton onClick={onClick}>
      {isSelected ? (
        <i className="fa-regular fa-circle-dot"></i>
      ) : (
        <i className="fa-regular fa-circle"></i>
      )}{" "}
      {label}
    </PlainButton>
  );
};
