export const saveJson = (content: string) => {
  const currentDate = new Date();
  const fileName = `csv-to-json-${currentDate.getTime()}.json`;

  const blobContent = new Blob([content], {
    type: "application/json;charset=utf-8;",
  });

  const linkElement = document.createElement("a");
  const href = URL.createObjectURL(blobContent);
  linkElement.href = href;
  linkElement.download = fileName;
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};
