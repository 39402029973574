import { useEffect, useRef, useState } from "react";

import { jsonToType } from "../libs/converter";
import { IconButton } from "../libs/shared";
import {
  ViewWrapper,
  ViewTitle,
  SmallToolsSection,
  ToolsSection,
  LeftInput,
  RightInput,
} from "../libs/shared/ui";
import { RadioButton } from "../libs/shared/ui/radio-button";
import { FileInput } from "../libs/shared/ui/file-input";

export default function JsonToTypescriptApp() {
  const fileRef = useRef<HTMLInputElement>(null);
  const [textInput, setTextInput] = useState<string | any>("");
  const [secondInput, setSecondInput] = useState("");
  const [splitType, setSplitType] = useState(true);

  // useEffect(() => {
  //   document.title = "Free Online JSON to TypeScript Converter";
  //   //@ts-ignore
  //   document.getElementsByTagName("meta")["description"].content =
  //     "A little handy tool to help TypeScript developers convert JSON data into types.";
  // }, []);

  const clearFileInput = () => {
    if (fileRef.current) {
      fileRef.current.value = "";
    }
  };

  const onFileChange = (event: any) => {
    const files = event.target.files;
    var reader = new FileReader();
    reader.onload = function () {
      setTextInput(reader.result);
    };
    if (files[0]) reader.readAsText(files[0]);
  };

  const convertJsonToType = () => {
    try {
      const resString = jsonToType(textInput, splitType);
      setSecondInput(resString);
    } catch (ex) {
      console.error("Failed to convert: ", ex);
    }
  };

  return (
    <>
      <ViewWrapper>
        <ViewTitle>JSON to TypeScript</ViewTitle>
        <SmallToolsSection>
          <FileInput>
            <input ref={fileRef} type="file" onChange={onFileChange} />
          </FileInput>
          <RadioButton
            label="Split Types"
            selected={splitType}
            onChange={(flag: boolean) => setSplitType(flag)}
          />
        </SmallToolsSection>
        <ToolsSection>
          <IconButton
            title="Clear"
            onClick={() => {
              setTextInput("");
              setSecondInput("");
              clearFileInput();
            }}
            className="fa-regular fa-trash-can"
          />
          <IconButton
            title="Convert"
            onClick={() => convertJsonToType()}
            className="fa-solid fa-arrow-right-arrow-left big cta"
          />
        </ToolsSection>
        <LeftInput
          value={textInput}
          onChange={(event: any) => setTextInput(event.target.value)}
          placeholder={"Add JSON data here"}
        ></LeftInput>
        <RightInput
          value={secondInput}
          onChange={(event: any) => setSecondInput(event.target.value)}
          placeholder={"Typescript code will show up here"}
        ></RightInput>
      </ViewWrapper>
    </>
  );
}
